<template>
  <v-card
    flat
    class="pa-3"
  >
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="600"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto pa-1"
            width="600"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <v-card-text v-else>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              rounded
              v-model="itemData.business_name"
              label="Nama Bisnis"
              dense
              outlined
              :rules="[v => !!v || 'Tidak boleh kosong']"
              required
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              rounded
              v-model="itemData.business_sector"
              label="Sektor Bisnis"
              dense
              outlined
              :rules="[v => !!v || 'Tidak boleh kosong']"
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              rounded
              v-model="itemData.pic_name"
              label="Nama PIC"
              dense
              outlined
              :rules="[v => !!v || 'Tidak boleh kosong']"
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              rounded
              v-model="itemData.pic_position"
              dense
              label="Jabatan PIC"
              outlined
              :rules="[v => !!v || 'Tidak boleh kosong']"
              required
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              rounded
              v-model="itemData.email"
              label="Email"
              :rules="emailRules"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              rounded
              v-model="itemData.whatsapp"
              label="WhatsApp"
              dense
              outlined
              :rules="[v => !!v || 'Tidak boleh kosong']"
              required
            ></v-text-field>
          </v-col>

          <!-- alert -->
          <v-col cols="12" v-if="showAlert">
            <v-alert
              :color="alertStatus"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ msg }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>

          <!-- DESKTOP -->
          <v-col cols="12" v-if="!isMobile()">
            <v-btn
            rounded
              color="primary"
              class="text-capitalize me-3 mt-4"
              @click="validate()"
              :disabled="isLoadingSubmit"
            >
              <span v-if="isLoadingSubmit">
              <v-progress-circular
                color="grey"
                size="25"
                indeterminate
                class="mr-2"
              ></v-progress-circular>
              Loading . . .
              </span>
              <span v-else>Simpan</span>
            </v-btn>
          </v-col>

          <!-- MOBILE -->
          <v-col cols="12" v-else>
            <v-btn
              rounded
              color="primary"
              block
              class="text-capitalize me-3 mt-4"
              @click="validate()"
              :disabled="isLoadingSubmit"
            >
              <span v-if="isLoadingSubmit">
              <v-progress-circular
                color="grey"
                size="25"
                indeterminate
                class="mr-2"
              ></v-progress-circular>
              Loading . . .
              </span>
              <span v-else>Simpan</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import axios from 'axios'

export default {
  setup() {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
      isLoading:true,
      isLoadingSubmit:false,
      itemData:[],
      valid:true,
      emailRules: [
        v => !!v || 'Tidak boleh kosong',
        v => /.+@.+\..+/.test(v) || 'Email tidak valid',
      ],
      userId:'',
      msg:'loading...',
      alertStatus:'primary',
      showAlert:false,
    }
  },
  mounted(){
    let user = JSON.parse(localStorage.getItem('user'));
    this.userId = user.user_id
    this.loadProfile()
  },
  methods:{
    loadProfile(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'users/'+this.userId, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            this.itemData = response.data.data
            setTimeout(() => { 
              this.isLoading = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    submit(){
      this.isLoadingSubmit = true
      let token = localStorage.getItem('token')
      axios.put(
        process.env.VUE_APP_API_ENDPOINT + 'users/'+this.userId, {
          business_name: this.itemData.business_name,
          business_sector: this.itemData.business_sector,
          pic_name : this.itemData.pic_name,
          pic_position : this.itemData.pic_position,
          email : this.itemData.email,
          whatsapp : this.itemData.whatsapp
        }, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            this.msg = response.data.message
            setTimeout(() => { 
              this.isLoadingSubmit = false
              this.alertStatus = 'primary'
              this.showAlert = true
              setTimeout(() => { 
                this.showAlert = false
              }, 2000);
            }, 200);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    validate () {
      let checkValid = this.$refs.form.validate()
      if(checkValid){
        // this.goToChooseInfluencer('valid');
        this.submit()
      }
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
