<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1">
      <div class="">
        <v-card id="account-setting-card" outlined class="round" elevation="0">
          <!-- tabs -->
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.icon"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span class="text-capitalize">{{ tab.title }}</span>
            </v-tab>
          </v-tabs>

          <!-- tabs item -->
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <account-settings></account-settings>
            </v-tab-item>

            <v-tab-item>
              <account-password></account-password>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </div>

    <!-- DESKTOP -->
    <div v-else>
      <p class="headline black--text font-weight-bold">Profil</p>
      <v-card id="account-setting-card" class="round">
        <!-- tabs -->
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <!-- tabs item -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <account-settings></account-settings>
          </v-tab-item>

          <v-tab-item>
            <account-password></account-password>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import AccountSettings from './AccountSettings.vue'
import AccountPassword from './AccountPassword.vue'

export default {
  components: {
    AccountSettings,
    AccountPassword,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Akun', icon: mdiAccountOutline },
      { title: 'Kata Sandi', icon: mdiLockOpenOutline }
    ]

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'johnDoe',
        name: 'john Doe',
        email: 'johnDoe@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: 'The name’s John Deo. I am a tireless seeker of knowledge, occasional purveyor of wisdom and also, coincidentally, a graphic designer. Algolia helps businesses across industries quickly create relevant 😎, scaLabel 😀, and lightning 😍 fast search and discovery experiences.',
        birthday: 'February 22, 1995',
        phone: '954-006-0844',
        website: 'https://themeselection.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    }

    return {
      isMobileWidth:0,
      tab,
      reload:true,
      tabs,
      accountSettingData,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
      },
    }
  },
  mounted(){
    if(!localStorage.getItem('token')){
      this.$router.push({name:'login'});
    }

    
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })    
  },
  methods:{
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  }
}
</script>
