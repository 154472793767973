<template>
  <div>
    <div v-if="isMobileWidth === 1">
      <v-col  cols="12" class="pl-7 pr-7 pt-6">
        <profile-account></profile-account>
      </v-col>
    </div>
    <v-row v-else >
      <v-col cols="12">
        <profile-account></profile-account>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfileAccount from './components/ProfileAccount.vue'

export default {
  name: 'profile',
  metaInfo: {
    title: 'Profile'
  },
  components: {
    ProfileAccount,
  },
  setup() {
    return {
      isMobileWidth:0
    }
  },
  mounted(){
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })

    if(!localStorage.getItem('token')){
      this.$router.push({name:'login'});
    }
  },
  methods:{
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  }
}
</script>
